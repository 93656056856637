import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Hero from '../components/backgroundSection';
import {
  Section,
  Title,
  TitleOutline,
  Text,
  LettersAnimation,
  Variant,
  HeadingType,
} from '../components/components';
import usePosition from '../components/usePosition';
import '../styles/about.css';
import styled from 'styled-components';
import Carousel, { ICarousel } from '../components/carousel';

const CustomSection = styled(Section)`
  .first-section {
    background: white;
    width: 100%;
    height: auto;
    display: flex;
    flex-flow: column;
    align-items: start;
  }
  .first-section-title {
    padding-bottom: 34px;
  }
  .first-section-text {
    max-width: 672px;
    max-height: 600px;
    text-align: left;
  }
  .mt-40 {
    margin-top: 40px;
  }
  .max-width-445 {
    max-width: 445px;
  }
  .height-600 {
    height: 600px;
  }
  .max-width-545 {
    max-width: 545px;
  }
  .max-width-580 {
    max-width: 580px;
  }
  .pt-40 {
    margin-top: 40px;
  }
  @media (min-width: 768px) {
    .first-section {
      align-items: center;
    }
    .first-section-text {
      text-align: center;
      max-width: 672px;
    }
  }
  @media (min-width: 992px) {
    .first-section {
      align-items: center;
    }
    .first-section-text {
      text-align: center;
      max-width: 672px;
    }
  }
`;

const DivSpace = styled.div`
  height: 25px;
  @media (min-width: 768px) {
    height: 180px;
  }
  @media (min-width: 992px) {
    height: 360px;
  }
`;

const data: ICarousel[] = [
  {
    title: 'Research / Strategy',
    description: (
      <div className="pt-4">
        <Title desktopsize="24px" tabletsize="24px" mobilesize="24px" className="mb-3">
          Every project begins asking questions
        </Title>
        <Text size="16px" className="mb-3">
          Usually everything starts with a call or an email, where we provide basic information
          about ourselves and how we work and ask some questions, but above all we listen.
        </Text>
        <Text size="16px">
          With all this information, it is time to analyze it, discuss it and identify the best way
          to meet customer expectations and especially those of the end user, the person who will
          use the product.
        </Text>
      </div>
    ),
    image: 'about-research@2x.png',
    altTextImage: 'Research and strategy plans',
  },
  {
    title: 'Design',
    description: (
      <div className="pt-4">
        <Title desktopsize="24px" tabletsize="24px" mobilesize="24px" className="mb-3">
          How your product will look and feel
        </Title>
        <Text size="16px" className="mb-3">
          At this stage we not only give direction to the data but we create a relationship between
          it and how it will affect the user experience through language, design and technology.
        </Text>
        <Text size="16px">
          We create flowcharts, wireframes, mockups and prototypes to have a visual representation
          of the project and in each task we’ll ask you and gather feedback to improve the whole
          product experience.
        </Text>
      </div>
    ),
    image: 'about-design@2x.png',
    altTextImage: 'Sample of mobile designs',
  },
  {
    title: 'Development',
    description: (
      <div className="pt-4">
        <Title desktopsize="24px" tabletsize="24px" mobilesize="24px" className="mb-3">
          Not just coding, but tight planning and implementation
        </Title>
        <Text size="16px" className="mb-3">
          Which technology suits your project best, the resources that we are going to use, and the
          best coding and testing practices are all things that we look at at this stage of your
          project.
        </Text>
      </div>
    ),
    image: 'about-development@2x.png',
    altTextImage: 'Development and engagement',
  },
  {
    title: 'Launch / Delivery',
    description: (
      <div className="pt-4">
        <Title desktopsize="24px" tabletsize="24px" mobilesize="24px" className="mb-3">
          Secure delivery of your product
        </Title>
        <Text size="16px" className="mb-3">
          Your product is done and we're ready to launch it to market and let the end user use it.
        </Text>
        <Text size="16px">
          We'll ensure your project behave as planned and we'll keep a continuous enhancement until
          the end.
        </Text>
      </div>
    ),
    image: 'about-launch@2x.png',
    altTextImage: 'Launched product',
  },
];
const CustomTitle = styled(Title)`
  font-family: Kollektif;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 43px;
  .fit-content {
    width: fit-content !important;
    display: block;
    font-family: Kollektif;
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 58px;
    padding-right: 0;
  }
  @media (min-width: 768px) {
    font-family: Kollektif;
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 58px;
    .fit-content {
      display: inline;
      font-family: Kollektif;
      font-style: normal;
      font-weight: bold;
      font-size: 62px;
      line-height: 74px;
    }
  }

  @media (min-width: 992px) {
    font-family: Kollektif;
    font-style: normal;
    font-weight: bold;
    font-size: 62px;
    line-height: 74px;
    .fit-content {
      font-family: Kollektif;
      font-style: normal;
      font-weight: bold;
      font-size: 116px;
      line-height: 139px;
    }
  }
`;
const About = ({ location }) => {
  usePosition();
  return (
    <Layout
      navbarBackground="rgba(255, 255, 255, 0.8)"
      navbarColor="#3C4356"
      className="about-page"
    >
      <SEO title="About" />
      <CustomSection
        padding="136px 0px 80px 0px"
        tabletPadding="138px 0px 80px 0px"
        mobilePadding="130px 0px 40px 0px"
        className="custom-container"
      >
        <div className="row justify-content-center g-0 gutt-16">
          <div className=" col-8 col-md-8 col-lg-8">
            <div className="first-section">
              <div className="first-section-title">
                <CustomTitle heading={HeadingType.H2} desktopsize="62px" color="#222">
                  <Title
                    variant={Variant.Gradient}
                    lineheightdesktop="139.2px"
                    desktopsize="116px"
                    className="my-3 fit-content pr-0 m-0"
                  >
                    Yeah,
                  </Title>
                  We Can Do That!
                </CustomTitle>
              </div>
              <Text lineheight="19.2px" size="16px" className="first-section-text">
                Have you noticed that creating an app has become increasingly complex over the past
                few years? Finding a professional web designer who can understand what makes your
                customers tick and include you in the app-building process feels like an epic quest
                for the Holy Grail, a web development company that checks all the boxes. Well, we’ve
                got great news! You can breathe a sigh of relief because your quest is over!
              </Text>
            </div>
          </div>
        </div>
      </CustomSection>
      <CustomSection padding="0px 0px" mobilePadding="0px 0px" tabletPadding="0px">
        <Hero
          fileName="image-about-cover@2x.jpg"
          ariaLabel="About us parallax image"
          style={{
            height: 400,
            backgroundAttachment: 'fixed',
            backgroundPosition: 'top',
            backgroundRepeat: 'no-repeat',
            zIndex: 0,
          }}
          tag="div"
        />
      </CustomSection>
      <Section
        className="overflow-hidden"
        padding="20px 0px"
        id="our-process"
        mobilePadding="0px 0px"
      >
        <LettersAnimation>
          <TitleOutline text="OUR PROCESS" />
        </LettersAnimation>
      </Section>
      <div className="overflow-hidden">
        <Carousel data={data} />
        <DivSpace />
      </div>
    </Layout>
  );
};

export default About;
