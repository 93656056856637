import React, { useState, useEffect } from 'react';
import { RoundedButton, Title, ButtonLink, List, Section } from './components';
import Hero from '../components/backgroundSection';
import SVGImage from './SVGImage';
import styled, { css } from 'styled-components';
import Fade from 'react-reveal/Fade';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

const Arrow = styled(RoundedButton)`
  z-index: 100;
  text-align: center;
  bottom: 0;
  cursor: pointer;
  border: 1px solid #5b26bf;
  user-select: none;
  ${props =>
    props.right
      ? css`
          left: calc(100% - 49px);
        `
      : css`
          left: 0%;
        `}
`;
const Dot = styled.li`
  font-size: 1.5em;
  text-shadow: 1px 1px 1px #fff;
  user-select: none;
`;
const Dots = styled.ol`
  text-align: center;
  z-index: 100;
  top: 0;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 1rem 0;

  list-style-type: none;

  li {
    margin: 0 8px;
    display: block;
    width: 16px;
    height: 4px;
    padding: 0;

    border: none;
    border-radius: 2px;
    background: #5b26bf;
    opacity: 0.2;

    text-indent: -9999px;
  }

  li.slide-active {
    background: #5b26bf;
    width: 36px;
    border-radius: 2px;
    opacity: 1;
    transition: all ease 500ms;
  }
`;
const ButtonsContainer = styled.div`
  &.controls {
    width: 132px;
    left: 16px;
    position: absolute;
  }
  &.controls .buttons {
    display: flex;
    justify-content: space-between;
  }
`;
const CustomSection = styled(Section)`
  .col-left {
    min-height: 444px;
    height: auto;
    padding-top: 80px;
    z-index: 1;
  }
  .first-container {
    min-height: 400px;
  }
  .first-container .title {
    margin-bottom: 40px;
  }
  .first-container .button {
    padding-top: 30px;
  }
  .col-right {
    position: relative;
  }
  .pr-0 {
    padding-right: 0px;
  }
  @media (max-width: 768px) {
    .col-left {
      min-height: 344px;
      padding-top: 40px;
    }
    .first-container {
      min-height: 300px;
    }
  }
  @media (max-width: 992px) {
    .pr-0 {
      padding-right: 0px;
      padding-left: 0px;
    }
  }
`;
const BackgroundContainer = styled.div`
  background-image: url(../../img/trapezoid.png);
  background-size: contain;
  background-position: right;
  background-repeat: no-repeat;
  width: 677px;
  height: 643px;
  position: absolute;
  top: 0;
  right: 0;
  @media (max-width: 768px) {
    background-position: right;
    width: 100%;
    height: 515px;
  }
  @media (min-width: 992px) {
    width: 874px;
    height: 960px;
  }
`;

export interface ICarousel {
  title: string | React.ReactNode;
  description: typeof List | React.ReactNode;
  button?: typeof ButtonLink | typeof RoundedButton;
  image: string;
  altTextImage: string;
}
interface ICarouselProps {
  data: Array<ICarousel>;
  timerDuration?: number;
  fadeDistance?: string;
}

const Carousel = ({ data, timerDuration = 5, fadeDistance = '100px' }: ICarouselProps) => {
  const initialTab = 0;
  const finalTab = data.length - 1;
  const [activeSlide, setActiveSlide] = useState(initialTab);
  const [active, setActive] = useState(true);
  const breakpoints = useBreakpoint();

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (breakpoints.sm && active) {
        setActiveSlide(activeSlide < finalTab ? activeSlide + 1 : initialTab);
      }
    }, timerDuration * 1000);
    return () => clearTimeout(timeout);
  }, [activeSlide]);
  const getContent = item => {
    return (
      <Fade up distance={fadeDistance}>
        <div className="first-container">
          {typeof item.title === 'string' ? (
            <Title desktopsize="36px" tabletsize="36px" mobilesize="36px" className="title">
              {item.title}
            </Title>
          ) : (
            item.title
          )}

          {item.description}

          {item.button && <div className="button d-flex justify-content-start">{item.button}</div>}
        </div>
      </Fade>
    );
  };
  const getImage = item => {
    return (
      <Fade right distance={fadeDistance}>
        <Hero
          fileName={item.image}
          ariaLabel={item.altTextImage}
          style={{
            backgroundPositionX: 'left',
            backgroundSize: 'auto 100%',
            margin: 'auto',
            height: breakpoints.md ? (breakpoints.sm ? 380 : 400) : 600,
          }}
          tag="div"
        />
      </Fade>
    );
  };

  return (
    <CustomSection
      className="position-relative custom-container outtl-32"
      padding="0px 0px"
      margin="40px 0px"
      id="our-process"
      mobilePadding="0px 0px"
      tabletPadding="20px 0px"
      style={{ zIndex: 1 }}
    >
      {data.length > 0 &&
        data.map((item, index) => {
          return (
            <div className="row position-relative d-md-none g-0" key={index}>
              <div className="col-8 col-left gutt-16">{getContent(item)}</div>
              <div className="col-8 col-right position-relative">
                <BackgroundContainer role="img" aria-label="trapezoid background" />
                {getImage(item)}
              </div>
            </div>
          );
        })}
      <div className="row position-relative h-100 d-none d-md-flex guttl-16">
        <>
          <div className="col-md-3 offset-lg-1 col-lg-4 col-left">
            {data.length > 0 &&
              data.map((item, index) => {
                if (index === activeSlide) {
                  return <React.Fragment key={index}>{getContent(item)}</React.Fragment>;
                }
              })}
            <ButtonsContainer
              className="controls col-md-3 offset-lg-1 col-lg-4 guttl-16"
              id="controls"
            >
              <Fade>
                <Dots>
                  {Array(...Array(data.length)).map((val, index) => (
                    <Dot
                      key={index}
                      className={`${index === activeSlide ? 'slide-active' : ''}`}
                    ></Dot>
                  ))}
                </Dots>
                <div className="buttons">
                  <Arrow
                    onClick={() => {
                      setActiveSlide(activeSlide - 1);
                      if (active) {
                        setActive(false);
                      }
                    }}
                    className="btn-white rounded"
                    disabled={activeSlide === 0}
                    title="Previous"
                  >
                    <SVGImage
                      className="work-icon"
                      src={`icon-previous-purple.svg`}
                      alt="Arrow previous icon"
                      height={24}
                      width={24}
                    />
                  </Arrow>
                  <Arrow
                    right
                    onClick={() => {
                      setActiveSlide(activeSlide + 1);
                      if (active) {
                        setActive(false);
                      }
                    }}
                    className="btn-white rounded"
                    disabled={activeSlide === data.length - 1}
                    title="Next"
                  >
                    <SVGImage
                      className="work-icon"
                      src={`icon-next-purple.svg`}
                      alt="Arrow next icon"
                      height={24}
                      width={24}
                    />
                  </Arrow>
                </div>
              </Fade>
            </ButtonsContainer>
          </div>
          <div className="col-lg-7 col-md-5 col-right pr-0 position-relative">
            <BackgroundContainer role="img" aria-label="trapezoid background" />
            {data.length > 0 &&
              data.map((item, index) => {
                if (index === activeSlide) {
                  return <React.Fragment key={index}>{getImage(item)}</React.Fragment>;
                }
              })}
          </div>
        </>
      </div>
    </CustomSection>
  );
};

export default Carousel;
